<template>
  <div class=" text-gray-800 text-5xl font-bold leading-[48px] mt-[100px] montsr mb-[72px]">Часто задаваемые вопросы</div>
  <div v-for="faq in faqs" :key="faq.id">
    <FAQ :accordion-title="faq.attributes.quest" :accordion-content="faq.attributes.answ"/>
  </div>
</template>

<script>
import FAQ from './FAQ.vue'
import axios from 'axios';


export default{
  components: {
    FAQ,
  },
  data () {
      return {
        faqs: [],
        error: null
      }
    },
  async mounted () {
      try {
        const response = await axios.get('https://zodchiy72.ru/strapi/api/faq?populate=deep')
        this.faqs = response.data.data
      } catch (error) {
        this.error = error;
      }

    }
  }
</script>