<template>
  <div>
    <button
      class="fixed bottom-4 right-4 z-[60] bg-blue-500 hover:bg-blue-700 text-white text-sm rounded-full px-[20px] py-[20px]"
      @click="scrollToTop"
      v-show="showScrollButton">
      <img class="w-4" src="../../assets/icons/upArrow.png" alt="">
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showScrollButton: false
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.showScrollButton = window.pageYOffset > 0;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
