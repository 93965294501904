<template>
  <transition name="modal-fade" v-if="showWindow == true">

  <div @click="showWindow = false" class="modal-backdrop w-full h-full z-[150] bg-slate-50">
    <div @click.stop class="w-[300px] md:w-[600px] h-auto shadow-xl p-6 bg-slate-50 rounded-[10px]">
        <div>
            <h1 class="text-center montsr font-bold text-[18px]">Записаться на услугу</h1>
            <div class="border-[1px] rounded-[15px] my-[12px] border-blue-600"></div>
        </div>
        <form @submit.prevent="submitForm" class="mt-[24px]" v-if="showSubmit == false && loading == false">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="w-full relative">
              <input type="text" placeholder="Фамилия Имя Отчество" class="border py-1 w-full pl-[14px] border-blue-600 rounded-[10px]" v-model="formData.data.FIO" required />
              <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
            </div>
            <div class="w-full relative">
              <input type="email" placeholder="sotsz@tyuiu.ru | почта" class="border py-1 pl-[14px] w-full border-blue-600 rounded-[10px]" v-model="formData.data.MyMail" required />
              <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
            </div>
            <div class="w-full relative">
              <input type="text" v-maska data-maska="+7 (###) ###-##-##" placeholder="+7(999)-999-9999" class="border py-1 pl-[14px] w-full border-blue-600 rounded-[10px]" v-model="formData.data.Phone" required @input="formatPhoneNumber"/>
              <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
            </div>
            <input type="text" placeholder="Комментарий" class="border py-1 border-blue-600 pl-[14px] rounded-[10px]" v-model="formData.data.Comment" />

            <!-- для пробного -->

            <!-- Заменить слово в на "пробное" -->
            <select type="text" placeholder="Выберите тренера" class="border py-1 pl-[14px] border-blue-600 rounded-[10px]" v-model="formData.data.demo.trener"  v-if="formData.data.Usluga.toLowerCase().includes('пробное')">
              <option value="" disabled selected>Выберите тренера</option>
              <option v-for="trener in trenersList" :key="trener.id">{{trener}}</option>
            </select>

            <select type="text" placeholder="Выберите дату" class="border py-1 pl-[14px] border-blue-600 rounded-[10px]" v-model="formData.data.demo.day" v-if="formData.data.demo.trener">
              <option value="" disabled selected>Выберите время</option>
              <option v-for="date in selectTrener" :key="date.id">{{date.day}}</option>
            </select>

            <!-- для пробного -->

            <input readonly type="text" placeholder="Услуга" class="border py-1 md:col-span-2 pl-[14px] border-blue-600 rounded-[10px]" v-model="formData.data.Usluga" required />


            <button @click="showWindow=false" class="flex mt-[15px] px-2 py-3 w-full h-auto mx-auto justify-center hover:bg-red-700 border border-red-700 rounded-[15px] hover:text-white text-opacity-80 hover:font-bold text-[16px] montsr leading-[14px]">Отменить</button>
            <button class="flex mt-[15px] px-2 py-3 w-full h-auto mx-auto justify-center hover:bg-blue-700 border border-blue-700 rounded-[15px] hover:text-white  text-opacity-80 hover:font-bold text-[16px] montsr leading-[14px]" type="submit">Отправить заявку</button>
          </div>
        </form>

        <div class="items-center" v-else-if="loading == true">
          <loader/>
        </div>

        <div class="grid grid-rows-2 items-center" v-else>
          <svg fill="none" stroke="currentColor" class="w-24 h-24  mx-auto stroke-green-500/90" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <p class="montsr text-center text-[18px]">Заявка на запись успешно отправлена</p>
          <button @click="showWindow = false" class="bg-gradient-to-br from-green-600/60 via-sky-500/60 to-blue-600/70 hover:to-green-600/60 hover:from-blue-600/70 w-full py-2 rounded-xl text-white font-bold">Отлично!</button>
        </div>
    </div>
  </div>


  </transition>
</template>

<style>
  /* input {
    transition: border-color 0.3s ease-in-out;
  } */

  input:focus {
    border-color: blue !important;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(28, 27, 110, 0.541);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input::placeholder {
    font-size: 14px; /* Размер шрифта, который вам подходит */
  }
</style>



<script>
  import axios from "axios";
  import loader from "../sharing/SiteLoader.vue"
  import { vMaska } from "maska"

  export default {
    directives: { maska: vMaska },

    props:{
      show: Boolean,
      serviceName: String,
      trenersList: Array,
      scheduleList: Array,
    },
    components:{
        loader
      },
    data(){

        return{
          showSubmit: false,
          loading: false,
          showWindow: this.show,


          // formData: {
          //   data: {
          //     FIO: '',
          //     MyMail: '',
          //     Phone: '',
          //     Comment: '',
          //     Trener: '',
          //     Date: '',
          //     Usluga: this.serviceName,
          //     Subject: 'Запись',
          //   },
          // },

          formData: {
            data: {
              FIO: '',
              MyMail: '',
              Phone: '',
              Comment: '',
              Usluga: this.serviceName,
              Subject: this.serviceName.toLowerCase().includes('пробное') ? "Запись (пробное)" : "Запись",
              demo:{
                demo: this.serviceName.toLowerCase().includes('пробное'),
                day:'',
                trener: ''
              }
            },
          },
        }
    },
    computed: {
      selectTrener(){
        return this.scheduleList.slice().sort((a, b) => a.day.localeCompare(b.day)).filter(item => item.trener.includes(this.formData.data.demo.trener))
      },
    },

    methods: {


      formatDateString(dateString) {
        const parts = dateString.split('-');
        if (parts.length === 3) {
          const year = parts[0];
          const month = parts[1];
          const day = parts[2];
          return `${day}.${month}.${year}`;
        } else {
          return dateString;
        }
      },

      async submitForm() {
        this.loading = true
        console.log(this.formData)

        const response = await axios.post('https://zodchiy72.ru/strapi/api/msgs', this.formData, {
        // const response = await axios.post('http://localhost:1337/api/msgs', this.formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        this.loading = false
        if(response.status == 200){
          this.showSubmit = true
        }
      }
    },

    async mounted(){
      // console.log(this.scheduleList)
    }
};
</script>