<template>
  <div>
    <NavBar/>
    <div class="text-gray-800 text-[38px] md:text-[48px] font-bold leading-[48px] mt-[100px] mb-[72px] text-center md:text-left montsr">Наша команда</div>
    <SearchBar class="hidden"/>
    <loader v-if="team.length < 1" class=""/>



  <div class="flex justify-center">
    <div class="grid grid-cols-1 lg:grid-cols-2 1xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-10 ">

    <div v-for="person in team" :key="person.id">
      <TeamCard :id="person.id"
          :imgPath="person.attributes.photo.data !== null ? 'https://zodchiy72.ru/strapi/' + person.attributes.photo.data.attributes.url.toString() : 'https://zodchiy72.ru/strapi/uploads/placeholder_User_8782a4a086.png'"
          :name="person.attributes.Name"
          :position="person.attributes.position"
          :Show="person.attributes.Show"
          />

    </div>

    </div>
  </div>

  <LoadMoreButton @click="loadMore" class="mt-[72px]" v-if="showLoadButton == true && page != countPage"/>
  <loader v-if="showLoadButton == false" class=""/>

  <div class="flex h-[777px] md:h-[460px]"/><Footer class=""/>




  </div>
</template>

<script>
  import loader from '../sharing/SiteLoader.vue'
  import NavBar from '../sharing/NavBar'
  import SearchBar from '../sharing/SearchBar.vue'
  import LoadMoreButton from '../sharing/LoadMore.vue'
  import Footer from '../sharing/FooterBar.vue'
  import TeamCard from './TeamCard.vue'
  import axios from 'axios';


  export default {
    components: {
      loader,
      NavBar,
      Footer,
      SearchBar,
      LoadMoreButton,
      TeamCard,
    },
    data () {
      return {
        showLoadButton: true,
        page: 1,
        countPage: 1,
        team: [],
        error: null
      }
    },
    methods:{
      async loadMore(){
        this.showLoadButton = false
        this.page++
        const response = await axios.get('https://zodchiy72.ru/strapi/api/teams?populate=deep&pagination[pageSize]=8&fields[0]=Show&fields[1]=Name&fields[2]=position&fields[3]=Tags&pagination[page]='+this.page+'&filters[Show][$ne]=false')
        var data = response.data.data
        console.log(this.page)
        console.log(this.countPage)
        data.forEach((element) => {
          this.team.push(element);
        });
        this.showLoadButton = true
      },
    },
    async mounted () {
      try {
        const response = await axios.get('https://zodchiy72.ru/strapi/api/teams?populate=deep&pagination[pageSize]=8&fields[0]=Show&fields[1]=Name&fields[2]=position&fields[3]=Tags&pagination[page]=1&filters[Show][$ne]=false')
        this.team = response.data.data
        this.countPage = response.data.meta.pagination.pageCount
      } catch (error) {
        this.error = error;
      }
    },
    computed: {
      filteredTeam() {
        if (!this.searchText) {
          return this.team;
        } else {
          const searchQuery = this.searchText.toLowerCase();
          return this.team.filter(
            (person) =>
              person.attributes.Name.toLowerCase().includes(searchQuery) ||
              person.attributes.position.toLowerCase().includes(searchQuery)
          );
        }
      },
    },
  }
</script>
