<template>
  <transition name="modal-fade" v-if="showWindow == true" class="z-[100]">

  <div @click="showWindow = false" class="modal-backdrop w-full h-full z-50 bg-slate-50">
    <div @click.stop class="w-[300px] md:w-[600px] h-auto shadow-xl p-6 bg-slate-50 rounded-[10px]">
        <div>
            <h1 class="text-center montsr font-bold text-[18px]">Перенос занятий</h1>
            <div class="border-[1px] rounded-[15px] my-[12px] border-blue-700/70"></div>
        </div>
        <form @submit.prevent="submitForm" class="md:mt-[24px]" v-if="showSubmit == false && loading == false">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="w-full relative">
                <input type="text" placeholder="Фамилия Имя Отчество" class="border py-1 w-full pl-[14px] border-blue-600 rounded-[10px]" v-model="formData.data.FIO" required />
                <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
              </div>
              <div class="w-full relative">
                <input type="email" placeholder="sotsz@tyuiu.ru | почта" class="border py-1 pl-[14px] w-full border-blue-600 rounded-[10px]" v-model="formData.data.MyMail" required />
                <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
              </div>
              <div class="w-full relative">
                <input type="text" v-maska data-maska="+7 (###) ###-##-##" placeholder="+7(999)-999-9999" class="border py-1 pl-[14px] w-full border-blue-600 rounded-[10px]" v-model="formData.data.Phone" required @input="formatPhoneNumber"/>
                <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
              </div>
              <input type="text" placeholder="Комментарий" class="border py-1 border-blue-600 pl-[14px] rounded-[10px]" v-model="formData.data.Comment" />
              <div class="md:col-span-2 flex-row flex montsr text-center md:text-left text-[14px]">Откуда переносим <p class="text-red-500 font-bold">*</p></div>
              <input type="date" :min="nowDate" class="border py-1 border-blue-600 w-full pl-[14px] rounded-[10px]" v-model="formData.data.DateFrom" required />
              <input type="time" class="border py-1 border-blue-600 w-full pl-[14px] rounded-[10px]" v-model="formData.data.TimeFrom" required />
              <div class="md:col-span-2 flex-row flex montsr text-center md:text-left text-[14px]">Куда переносим <p class="text-red-500 font-bold">*</p></div>
              <input type="date" class="border py-1 border-blue-600 w-full pl-[14px] rounded-[10px]" v-model="formData.data.DateTo" required />
              <input type="time" class="border py-1 border-blue-600 w-full pl-[14px] rounded-[10px]" v-model="formData.data.TimeTo" required />

              <div class="w-full md:col-span-2 relative">
                <input type="text" placeholder="Услуга" class="border py-1 pl-[14px] w-full border-blue-600 rounded-[10px]" v-model="formData.data.Usluga" required />
                <span class="absolute right-1 text-red-500 font-bold z-50">*</span>
              </div>

              <button @click="showWindow=false" class="flex mt-[6px] md:mt-[15px]  px-2 py-3 w-full h-auto mx-auto justify-center hover:bg-red-700 border border-red-700 rounded-[15px] hover:text-white text-opacity-80 hover:font-bold text-[16px] montsr leading-[14px]">Отменить</button>
              <button class="flex md:mt-[15px] px-2 py-3 w-full h-auto mx-auto justify-center bg-blue-700 border border-blue-700 rounded-[15px] text-white  text-opacity-80 font-bold hover:scale-110 transition duration-300 text-[16px] montsr leading-[14px]" type="submit">Отправить заявку</button>
            </div>
        </form>

        <div class="items-center" v-else-if="loading == true">
          <loader/>
        </div>

        <div class="grid grid-rows-2 items-center" v-else>
          <svg fill="none" stroke="currentColor" class="w-24 h-24  mx-auto stroke-green-500/90" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <p class="montsr text-center text-[18px]">Заявка на перенос успешно отправлена</p>
          <button @click="showWindow = false" class="bg-gradient-to-br from-green-600/60 via-sky-500/60 to-blue-600/70 hover:to-green-600/60 hover:from-blue-600/70 w-full py-2 rounded-xl text-white font-bold">Отлично!</button>
        </div>
    </div>
  </div>


  </transition>
</template>

<style>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(28, 27, 110, 0.541);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input::placeholder {
    font-size: 14px; /* Размер шрифта, который вам подходит */
  }
</style>



<script>
  import axios from "axios";
  import loader from "../sharing/SiteLoader.vue"
  import { vMaska } from "maska"

  export default {
    directives: { maska: vMaska },
    components:{
      loader
    },
    props:{
      show: Boolean,
    },
    mounted(){
      this.nowDate = new Date().toISOString().split('T')[0]
    },
    data(){
      return{
        nowDate: null,
        showSubmit: false,
        loading: false,
        showWindow: this.show,
        formData: {
          data: {
            FIO: '',
            MyMail: '',
            Phone: '',
            Comment: '',
            DateFrom: '',
            DateTo: '',
            TimeFrom: '',
            TimeTo: '',
            Usluga: '',
            Subject: 'Перенос',
            demo: null
          },
        }
      }
    },
    methods: {
      formatDateString(dateString) {
        const parts = dateString.split('-');
        if (parts.length === 3) {
          const year = parts[0];
          const month = parts[1];
          const day = parts[2];
          return `${day}.${month}.${year}`;
        } else {
          return dateString;
        }
      },

      async submitForm() {
        this.formData.data.DateFrom = this.formatDateString(this.formData.data.DateFrom)
        this.formData.data.DateTo = this.formatDateString(this.formData.data.DateTo)
        this.loading = true
        const response = await axios.post('https://zodchiy72.ru/strapi/api/msgs', this.formData, {
        // const response = await axios.post('http://localhost:1337/api/msgs', this.formData, {
            headers: {
                'Content-Type': 'application/json',
              },
            });
            this.loading = false
            if(response.status == 200){
              this.showSubmit = true
            }
      }
    }
};
</script>