import { createRouter, createWebHistory } from "vue-router";


import newCalendarPage from "./components/CalendarPage/CalendarPageBeta.vue" //!

import mainPage from "./components/mainPage/mainPage.vue";
import newsPage from "./components/newsPage/newsPage.vue";
import ServicesPage from "./components/ServicesPage/ServicesPage.vue"
import TeamPage from "./components/TeamPage/TeamPage.vue"
import documentsPage from "./components/documentsPage/documentsPage.vue"
import contactsPage from "./components/contactsPage/contactsPage.vue"
import CalendarPage from "./components/CalendarPage/CalendarPage.vue"
import insideNew from "./components/newsPage/InsideNew"
import insideService from "./components/ServicesPage/insideService"
import teamPerson from "./components/TeamPage/teamPerson.vue"
// import ReScheduling from "./components/CalendarPage/ReScheduling.vue"
import AuthPanel from "./components/Cabinet/AuthPanel.vue"
// import MainCabPage from "./components/Cabinet/MainCabPage.vue"
// import SchedulePage from "./components/Cabinet/SchedulePage.vue"
// import MedicalReferences from "./components/Cabinet/MedicalReferences.vue"
// import myCards from "./components/Cabinet/MyCards.vue"
// import myProfile from "./components/Cabinet/myProfile.vue"
import trener from "./components/Cabinet/trenerPage.vue"



const router = createRouter({
  history: createWebHistory(),
  routes:[
    {path: '', component: mainPage, alias:'/'},
    {path: '/news', component: newsPage, alias:'/news'},
    {path: '/services', component: ServicesPage, alias:'/services'},
    {path: '/team', component: TeamPage, alias:'/team'},
    {path: '/docs', component: documentsPage, alias:'/docs'},
    {path: '/contactsPage', component: contactsPage, alias:'/contactsPage'},
    {path: '/schedule', component: CalendarPage, props: true, alias:'/schedule'},
    {path: '/schedule/2', component: newCalendarPage, props: true, alias:'/schedule/2'}, //!
    {path: '/new/:id', component: insideNew, },
    {path: '/service/:id', component: insideService, },
    {path: '/team/:id', component: teamPerson, },
    // {path: '/ReScheduling', component: ReScheduling, },
    {path: '/auth', component: AuthPanel, },
    // {path: '/cab', component: MainCabPage, },
    // {path: '/cab/schedule', component: SchedulePage, },
    // {path: '/cab/references', component: MedicalReferences, },
    // {path: '/cab/cards', component: myCards, },
    // {path: '/cab/profile', component: myProfile, },
    {path: '/cab/trener', component: trener, },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Восстановление позиции прокрутки, если она была сохранена
    } else {
      return { left: 0, top: 0 }; // Прокрутка вверх страницы, если позиция не была сохранена
    }
  },
});

export default router;