<template>
    <div class="grid grid-cols-7 justify-items-center justify-center mb-6 mt-[72px] mx-[20px]">
      <div v-for="item in weekDates" :key="item.id">
        <date-date @click="this.swiper.slideTo(item.id, 1000)" :date-num="item.num" :day-name="item.name" :active="item.active"/>
      </div>
    </div>

    <swiper
        class="z-0 swiper"
        :slides-per-view="slidesOnScreen"
        :space-between="0"
        @slideChange="dayChange()"
    >
      <swiper-slide class="flex flex-col min-h-screen" v-for="day in weekDates" :key="day.id">
        <div v-for="urok in events[day.id]" :key="urok.id">
          <schedule-card :desc="urok.description" :name="urok.title" :usluga="urok.content" :fitnesRoom="urok.fitnesRoom" :time="urok.start + ' - ' + urok.end" :replaced='urok.replaced' :active="false" />
        </div>
        <p class="text-center text-blue-700 font-bold montsr" v-if="events[day.id].length < 1">В этот день недели занятия отсутствуют</p>
      </swiper-slide>
    </swiper>

</template>

<script>

  import ScheduleCard from "./scheduleCard.vue";
  import DateDate from "./dateDate.vue";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  // Import Swiper styles
  import 'swiper/css';
  export default {
    props: {
      events: {
        type: Array,
        required: true,
      },
    },
    components: {
      Swiper,
      SwiperSlide,
      DateDate,
      ScheduleCard
    },
    data() {
      return{
        swiper: Object,
        slidesOnScreen: 1,

        activeSlide: 0,
        weekDates: [],
      }
    },

    methods: {
      dayChange(){
        this.weekDates[this.activeSlide].active=false
        this.activeSlide = this.swiper.activeIndex
        this.weekDates[this.activeSlide].active=true
      },

      getWeekDays() {
        const daysMap = {
          "0": 'Пн',
          "1": 'Вт',
          "2": 'Ср',
          "3": 'Чт',
          "4": 'Пт',
          "5": 'Сб',
          "6": 'Вс',
        }

        for (var i = 0; i < 7; i++) {
          this.weekDates.push({
            name: daysMap[i],
            active: false,
            id: i,
          })
        }
        // this.weekDates[0].active = true
        // let dayNum = 0
        // for (var i = 0; i < 7; i++) {
        //   let tempDate = new Date(new Date().setDate(new Date().getDate() + i + dayNum))

        //   let weekDayName = daysMap[tempDate.getDay()]
        //   let weekDayNum = tempDate.getDate()

        //   this.weekDates.push({
        //     name: weekDayName.toString(),
        //     num: weekDayNum.toString(),
        //     active: false,
        //     id: i,
        //   })
        // }
        // this.weekDates[0].active = true
      }
    },

    mounted() {
      this.getWeekDays()
      if(window.innerWidth >= 1400){
        this.slidesOnScreen = 7
        this.weekDates[0].active = false
      }else{
        this.weekDates[0].active = true
      }
      this.swiper = document.querySelector('.swiper').swiper;
    },

  }
</script>
