<template>

  <div class="justify-center text-[12px] font-bold p-2 bg-white/60 shadow-sm flex w-[32px] md:w-[100px] items-center h-[50px] rounded-[10px] cursor-pointer mt-2"
  :class="{ 'border-2 border-blue-700': active}"
  >
    <p class="my-auto text-center">{{dayName}}</p>
    <!-- <div class="border-[1px] my-auto border-blue-700 mx-1"/> -->
    <!-- <p>{{dateNum}}</p> -->
  </div>

</template>

<script>
  export default {
    components: {

    },
    props:{
      active: Boolean,
      dayName: String,
      dateNum: String,
    }
  }
</script>