<template>
<div class="h-auto w-auto items-center mx-auto">
  <Carousel :autoplay="5000" :wrap-around="true" :breakpoints="breakpoints">
    <Slide class="" v-for="img1 in photos" :key="img1.id">
      <div class="carousel__item">
        <img class="rounded-[15px] object-top aspect-w-16 aspect-h-9 max-h-[745px] lg:w-[1600px] lg:h-[745px]" style="object-fit: cover;" :src="'https://zodchiy72.ru/strapi'+img1.attributes.url">
      </div>
    </Slide>
    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</div>



</template>


<script>
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

  import 'vue3-carousel/dist/carousel.css'
  import axios from 'axios';

  export default defineComponent({
    name: 'PhotoSlider',

    components: {
      Carousel,
      Slide,
      Navigation,
      Pagination,
    },
    data () {
      return {
        photos: [],
        error: null
      }
    },

    async created () {
      try {
        const response = await axios.get('https://zodchiy72.ru/strapi/api/slider?populate=deep')
        this.photos = response.data.data.attributes.Photo.data

        if(response != undefined && this.photos.length != 0){
          this.$emit('loaded', true);
        }
      } catch (error) {
        this.error = error;
      }

    }
  })
</script>
