<template>
  <div class=" text-gray-800 text-5xl font-bold leading-[48px] mt-[100px] montsr">Новости</div>
  <!-- {{news[0].attributes}} -->
  <div class="my-[72px]" v-if="news.length > 0">
    <BigNew  v-if="news.length>0" :id="news[0].id"  :header="news[0].attributes.Header" :middleDescription="news[0].attributes.middleDescription" :imgPath="news[0].attributes.Img.data.attributes.url"/>
    <MidNewCard v-if="news.length>0" :news="news"/>
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import BigNew from "./BigNew.vue"
import MidNewCard from "./MidNewCard.vue";
import axios from 'axios';


export default defineComponent({
  name: 'NewsBlock',

  components: {
    BigNew,
    MidNewCard,

  },
  data () {
      return {
        news: [],
        error: null
      }
    },
    async created () {
      try {
        const response = await axios.get('https://zodchiy72.ru/strapi/api/novostis?populate=deep&sort=id:DESC&pagination[page]=1&pagination[pageSize]=6&fields[0]=id&fields[1]=middleDescription&fields[2]=shortDescription&fields[3]=Header')
        this.news = response.data.data


        if(response != undefined && this.news.length != 0){
          this.$emit('loaded', true);
        }
      } catch (error) {
        this.error = error;
      }

    }
  })
</script>