<template>
  <div class="w-full h-full mx-6 px-3">

    <!-- <div class="mt-0 lg:mt-[48px] w-full scale-90 lg:scale-100">
      <p class="montsr font-bold text-[18px] md:text-[28px] line-clamp-2">Ваше личное расписание</p>
      <Schedule class=""/>
    </div> -->
    <div class="mt-[86px]">
      <p class="montsr font-bold text-[18px] mb-[16px] text-center md:text-left md:text-[28px] line-clamp-2">Заявки на пробное занятие</p>

      <!-- ! -->
      <div class="overflow-x-auto">
        <table class="w-full text-center">
          <tr class="montsr table-auto font-bold">
            <td class="">ФИО</td>
            <td class="">Услуга</td>
            <td class="">Почта</td>
            <td class="">Телефон</td>
            <td class="">Расписание</td>
            <td class="">Комментарий</td>
            <td class="">Статус</td>
            <td class="">ID</td>
          </tr>
          <tr class="montsr table-auto" v-for="item in records" :key="item.id">
            <td class="text-left ">{{item.attributes.FIO}}</td>
            <td class="">{{item.attributes.Usluga.split(' | ')[0]}}</td>
            <td class="">{{item.attributes.MyMail}}</td>
            <td class="w-48">{{item.attributes.Phone}}</td>
            <td class="">{{item.attributes.demo.day}}</td>
            <td class="">{{item.attributes.Comment}}</td>
            <td class="w-[110px]">
              <select @change="changeStatus(item.id, $event.target.value)" class="bg-blue-600 rounded-[10px] duration-500 text-white m-1 py-[4px] px-[24px] montsr hover:bg-black/0 hover:font-bold hover:border-blue-600 border-2 hover:text-blue-600">
                <option value="" disabled selected>{{item.attributes.Status}}</option>
                <option v-if="item.attributes.Status != 'Новая заявка'" disabled>Новая заявка</option>
                <option v-if="item.attributes.Status != 'Принято'">Принято</option>
                <option v-if="item.attributes.Status != 'Завершено'">Завершено</option>
              </select>
            </td>
            <td class="px-1">{{item.id}}</td>
          </tr>
        </table>
      </div>

      <div v-for="item in reschedulingWindows" :key=item>
        <ReScheduling
          :show="showRescheduling"
          v-if="showRescheduling == true"
      />
    </div>

    </div>
  </div>

  <div class="flex h-[700px] md:h-screen"/><Footer class="z-40"/>


</template>


<script>
  import Footer from '../sharing/FooterBar.vue'
  import axios from 'axios'
  // import SideBar from './SideBar.vue';
  // import ScheduleTable from './ScheduleTable.vue';
  // import Schedule from '../CalendarPage/CalendarSchedule.vue';

  export default {
    components: {
      // SideBar,
      Footer,
      // ScheduleTable,
      // Schedule,
    },
    data () {
      return{
        records: []
        }
    },

    async mounted(){
      let response = await axios.get('https://zodchiy72.ru/strapi/api/msgs?filters[demo][demo][$eq]=true&filters[Status][$ne]=Завершено&sort=id:desc&populate[demo][populate][komanda]=&filters[demo][komanda][Name][$eq]='+ 'Без тренера')
      for (let i = 0; i < response.data.data.length; i++) {
        this.records.push(response.data.data[i])
      }
    },
    methods:{
      async changeStatus(id, status){
        await axios.put('https://zodchiy72.ru/strapi/api/msgs/'+id, {
          data: {
            Status: status
          }
        })


      },
      ConvertDate(targetDateStr){
        // Разбиваем строку на составные части
        const parts = targetDateStr.split('-');
        const [year, month, day] = parts;
        return `${day}.${month}.${year}`
      }

    }
  }

</script>
