<template>
    <div class="flex-1 justify-center">

      <router-link :to="'/team/' + id" class="h-[480px] w-[300px] xl:w-[355px] bg-white items-center flex shadow-lg flex-col rounded-[15px]">
        <img class="aspect-w-16 aspect-h-9 w-full h-[410px] rounded-[15px] object-cover object-top max-h-[410px] " :src="imgPath" alt="">
        <p class="text-blue-800 px-2 text-[16px] pt-3 font-bold leading-[20px] montsr">{{ name }}</p>
        <p class="text-blue-800 px-2 line-clamp-1 text-[16px] montsr">{{ position }}</p>
      </router-link>


    </div>
</template>

<script>
  export default {
    components: {
    },
    props: {
      name: String,
      id: String,
      position: String,
      imgPath: String,
    },

    async mounted() {
    },
  }
</script>