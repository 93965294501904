<template>

  <div class="bg-white/60 shadow-md mx-[12px] rounded-xl h-[100px] grid grid-cols-1 mb-[10px] p-[13px] grid-rows-4 relative">

    <div v-if="active" class="absolute h-5 w-5  rounded-full bg-blue-700 animate-ping"></div>
    <div v-if="active" class="absolute h-5 w-5 rounded-full bg-blue-700"></div>

    <p class="text-gray-600 text-[12px] text-right">{{time}}</p>
    <p class="font-bold line-clamp-1 text-[12px]">{{ !fitnesRoom ? name : usluga }}</p>
    <p class="text-gray-600 line-clamp-1 text-[12px] ">{{ !fitnesRoom ? usluga : name }}</p>

    <div class="flex flex-row absolute bottom-0 items-center bg-blue-700 h-[25px] px-[12px] mt-[8px] py-[5px] rounded-tr-xl rounded-bl-xl left-0">
      <p class="text-[12px] font-bold text-white">{{ desc }}</p>
    </div>

    <div v-if="replaced" class="flex flex-row absolute bottom-0 items-center bg-red-500/75 h-[25px] px-[12px] mt-[8px] py-[5px] rounded-tl-xl rounded-br-xl right-0">
      <p class="text-[12px] font-bold text-white">Замена</p>
    </div>

  </div>
</template>

<script>
export default {
  components: {

  },
  props:{
    desc: String,
    active: Boolean,
    name: String,
    usluga: String,
    time: String,
    replaced: Boolean,
    fitnesRoom:Boolean,
  }


}
</script>