<template>
  <NavBar/>
  <div class="text-gray-800 text-[38px] md:text-[48px] font-bold leading-[48px] mt-[100px] mb-[32px] md:mb-[72px] text-center md:text-left montsr">Документы</div>
  <button @click="payListWindows++" class=" px-4 py-3 w-full md:w-1/3 mb-[16px] min-w-fit mx-auto justify-center transition duration-300 hover:bg-blue-700 border-2 border-blue-700 rounded-[15px] hover:text-white h-auto min-h-10 text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Заказать справку об оплатах</button>

  <template v-if="categories.length > 0 && docs.length > 0">
    <div class="" v-for="oneCategory in categories" :key="oneCategory.id">
      <AccordionSecond
        :accordionTitle="oneCategory.attributes.category"
        :accordionContent="getCategoryDocs(oneCategory.attributes.category)"
      />
    </div>

    <div v-for="item in payListWindows" :key=item>
      <PayListForm
        :show="true"
      />
    </div>

  </template>
</template>

<script>
import NavBar from '../sharing/NavBar'
import AccordionSecond from './AccordionDocs.vue'
import PayListForm from '../sharing/PayListForm.vue'
import axios from 'axios';

export default {
  components: {
    NavBar,
    AccordionSecond,
    PayListForm,
  },
  data () {
    return {
      categories: [],
      docs: [],

      payListWindows: 0
    }
  },
  methods: {
    getCategoryDocs(category){
      const sendDocs = [];
      for (let index = 0; index < this.docs.length; index++) {
        if (this.docs[index].attributes.doc.data.attributes.category === category) {
          sendDocs.push(this.docs[index]);
        }
      }
      return sendDocs;
    },
  },
  async mounted () {
    if (this.categories.length < 1 && this.docs.length < 1) {
      const response = await axios.get('https://zodchiy72.ru/strapi/api/kategoriya-dokumentovs');
      this.categories = response.data.data;
      const docsResponse = await axios.get('https://zodchiy72.ru/strapi/api/docs?populate=doc&populate=document');
      this.docs = docsResponse.data.data;
    }
  }
}
</script>