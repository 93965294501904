<template>
  <NavBar/>
  <div class="text-center montsr font-bold text-[23px] md:text-[46px] mt-[72px] line-clamp-2 mb-[32px]">{{ info.name }}</div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-x-24">
    <img class="aspect-w-16 aspect-h-9 w-full mx-auto mb-[32px] rounded-[15px] object-cover" :src="imgPath">
    <div v-html="info.description" class="montsr text-[18px] leading-9"/>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-x-24 mt-[32px]">
    <!-- ! -->
    <div>
      <h3 class="text-center montsr font-bold text-[28px] mb-4 ">Стоимость</h3>
      <div v-for="item in this.categoryList" :key="item.categoryList" class="" :class="{'border-[2px] border-blue-700 p-4 mb-4 rounded-[15px]': item != ' ' }">
      <h3 class="text-center montsr font-bold text-[23px] mb-4">{{item}}</h3>
        <div v-for="price in this.priceList" :key="price.descriptionPrice">
          <button @click="ChangeShowSignUp(price.descriptionPrice, price.priceNum)" class="flex mb-[16px] px-4 h-auto min-h-10 w-full flex-row items-center bg-blue-700 text-white hover:scale-110 transition duration-300 rounded-[15px]" v-if="price.category == item" >
            <img src="../../assets/icons/card.png" class="h-[20px] w-[20px]">
            <p class="text-opacity-80 text-[16px] montsr leading-[14px] py-3 pl-[8px] pr-1 ">{{ price.descriptionPrice }} </p>
            <p class="text-opacity-80 text-[16px] montsr leading-[14px] ml-auto w-auto pl-1 whitespace-nowrap font-bold"> От {{price.priceNum}} ₽</p>
          </button>
        </div>
      </div>
    </div>

    <!-- ! -->
    <div class="" v-if="cantSignUp != true">
      <h3 class="text-center montsr font-bold text-[28px] mb-4 ">Присоединяйтесь к нам</h3>
      <button v-if="canDemoSignUp == true" @click="ChangeShowSignUp('Пробное занятие', ' ')" class=" px-2 py-3 md:w-full mb-[16px] w-full mx-auto justify-center transition duration-300 hover:bg-blue-700 border border-blue-700 rounded-[15px] hover:text-white h-auto min-h-10 text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Записаться на пробное занятие</button>
      <button @click="ChangeShowSignUp" class=" px-2 py-3 md:w-full mb-[16px] w-full mx-auto justify-center transition duration-300 hover:bg-blue-700 border border-blue-700 rounded-[15px] hover:text-white h-auto min-h-10 text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Записаться</button>
      <RouterLink :to="'/schedule?myage=' + myage + '&myspace=' + myspace + '&myservice=' + info.name" v-if="showScheduleButton == true && myage != '' && myspace != ''" class="flex px-2 py-3 md:w-full h-auto min-h-10 mx-auto transition duration-300 justify-center hover:bg-blue-700 border border-blue-700 rounded-[15px] hover:text-white text-bold text-opacity-80 font-bold text-[16px] montsr leading-[14px]">Расписание</RouterLink>
    </div>

  </div>



  <div v-for="item in signUpWindows" :key=item>
    <SignUpService
    :show="showSignUp"
    :serviceName="info.name + selectPrice"
    :trenersList="this.trenersList"
    :scheduleList="this.scheduleList"
    v-if="showSignUp == true"
    />
  </div>


  <div class="flex h-[600px] md:h-[435px]"/><Footer/>

</template>

<script>
import NavBar from '../sharing/NavBar.vue'
import axios from 'axios';
import Footer from '../sharing/FooterBar.vue'
import SignUpService from '../sharing/SignUpService.vue';



export default {
  components: {
    SignUpService,
    NavBar,
    Footer
  },
  data() {
    return {
      cantSignUp: true,
      canDemoSignUp: false,
      signUpWindows: 0,
      showSignUp: false,
      showScheduleButton: false,
      myage: '',
      myspace: '',
      info: [],
      categoryList: [],
      imgPath: '',

      selectPrice: ' ',

      priceList: [],
      trenersList: [],
      scheduleList: [],
    }
  },
  methods: {
    ChangeShowSignUp(desc, price){
      if(this.cantSignUp != true){

        this.showSignUp = true
        this.signUpWindows++


        if (desc && price) {
          if(desc == "Пробное занятие"){
            this.selectPrice = ' | ' + desc
          }else{
            this.selectPrice = ' | ' + desc + ' от ' + price + ' рублей'
          }
        }else{
          this.selectPrice = ''
        }
      }
    },
    // formatDate(inputDate) {
    //   const date = new Date(inputDate);
    //   const day = String(date.getDate()).padStart(2, '0');
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const year = date.getFullYear();

    //   return `${day}.${month}.${year}`;
    // }
  },
  async mounted() {
    try {

      const response = await axios.get('https://zodchiy72.ru/strapi/api/catalogs/' + this.$route.params.id + '/?populate=*')
      this.info = response.data.data.attributes
      this.showScheduleButton = this.info.ShowScheduleButton
      this.imgPath = 'https://zodchiy72.ru/strapi' + this.info.photo.data.attributes.url
      this.cantSignUp = this.info.hideSignUp
      this.canDemoSignUp = this.info.canDemoSignUp
      // console.log(this.info.canDemoSignUp)


      if(this.showScheduleButton == true){
        this.myage = this.info.kategorii_dlya_raspisaniya.data.attributes.name
        this.myspace = this.info.pomeshheniya_dlya_raspisaniya.data.attributes.name
      }


      var priceString = this.info.PriceList.split('\n') // передавать прайс
      var category = ' '
      this.categoryList.push(category)
      for (let i = 0; i < priceString.length; i++) {
        if(priceString[i].split("=").length == 1 && priceString[i].split('>').length == 2){
          category = priceString[i].split('>')[1]
          this.categoryList.push(category)
        }else{
          this.priceList.push(
            {
              'category': category,
              'descriptionPrice': priceString[i].split("=")[0],
              'priceNum': priceString[i].split("=")[1]
            }
          )
        }

      }
    } catch (error) {
      this.error = error;
    }

    let FirstResponseTreners = await axios.get('https://zodchiy72.ru/strapi/api/schedule?populate=trener&filters[uslugi][name][$eq]='+this.info.name+'&fields[0]=trener&pagination[pageSize]=100')

    // console.log(FirstResponseTreners.data.meta.pagination.pageCount)

    for (let i = 1; i < FirstResponseTreners.data.meta.pagination.pageCount+1; i++) {
      let responseTreners = await axios.get('https://zodchiy72.ru/strapi/api/schedule?populate=trener&filters[uslugi][name][$eq]='+this.info.name+'&fields[0]=trener&fields[1]=day&fields[2]=StartTime&fields[3]=EndTime&fields[4]=description&pagination[pageSize]=100&pagination[page]='+i)
      for (let j = 0; j < responseTreners.data.data.length; j++) {
        console.log(responseTreners.data.data[j].attributes.trener.data.attributes.Name)

        if(!this.trenersList.includes(responseTreners.data.data[j].attributes.trener.data.attributes.Name)){
          this.trenersList.push(
            responseTreners.data.data[j].attributes.trener.data.attributes.Name
          )
        }
        this.scheduleList.push(
          {
            day: responseTreners.data.data[j].attributes.day + ' ' + responseTreners.data.data[j].attributes.StartTime.split(':')[0]+':'+responseTreners.data.data[j].attributes.StartTime.split(':')[1] +' - '+ responseTreners.data.data[j].attributes.EndTime.split(':')[0]+':'+responseTreners.data.data[j].attributes.EndTime.split(':')[1] + ' (' + responseTreners.data.data[j].attributes.description + ')  ',
            trener: responseTreners.data.data[j].attributes.trener.data.attributes.Name,
            id: responseTreners.data.data[j].attributes.trener.data.id
          }
        )
      }
    }



      // console.log(this.trenersList)
      // console.log(this.scheduleList)

  }
}
</script>
