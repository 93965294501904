<template>
    <div class="bg-white flex flex-col lg:flex-row justify-between w-full relative  rounded-[15px] mb-[72px] CardAnimation h-auto shadow-md lg:h-[400px]">
    <div class="w-full lg:w-1/2 lg:min-w-[460px]">
      <img class="aspect-w-16 aspect-h-9 h-full max-h-[480px]  w-full object-cover object-top rounded-[15px] flex overflow-hidden" :src=imgPath />
    </div>
    <div class="flex w-full pl-6 lg:pl-6 h-full flex-col justify-between pt-[12px] pr-6">
      <div class="h-auto">
        <div class="montsr font-bold leading-8 xl:leading-10 h-auto text-[18px] lg:text-[22px] xl:text-[28px] text-center line-clamp-2 mb-[14px]">{{ header }}</div>
        <div class="montsr text-gray-800 text-[14px] xl:text-[16px] 2xl:text-lg h-auto line-clamp-[8] text-justify font-medium items-center leading-[22px] lg:leading-[24px]">{{shortDescription}}</div>
      </div>
      <div class="flex flex-row h-auto items-center justify-between pt-[12px] lg:pt-0 pb-[12px]">
        <div class="flex flex-row items-center">
          <img src="../../assets/icons/TimeNewsIco.png" class="h-[20px] w-[20px]">
          <p class="text-stone-500 text-opacity-80 text-sm montsr leading-[14px] pl-[8px]">{{ date }}</p>
        </div>
        <router-link :to="'/new/'+id"><div class="h-10 w-auto px-4 rounded-[15px] text-[14px] xl:text-[16px] border-blue-600 flex justify-center items-center bg-blue-600 font-medium text-gray-50 hover:bg-blue-500 montsr">Подробнее</div></router-link>
      </div>

    </div>
  </div>
</template>

<style>
  .CardAnimation {
    background-color: #ffffff;
    transition: background-color 0.5s ease-in-out;
  }

  .CardAnimation:hover {
    animation: colorChange 1s;
  }

  @keyframes colorChange {
    0% {
      background-color: #ffffff;
    }
    50% {
      background-color: #c8d0ff3d;
    }
    100% {
      background-color: #ffffff;
    }
  }
</style>


<script>

  export default {
    props: {
      header: String,
      date: String,
      shortDescription: String,
      imgPath: String,
      id: String,
    },
  }
</script>