<template>
  <div class="flex relative flex-wrap my-[72px]">
    <div class="w-full xl:w-[50%] relative text-left">

      <router-link :to="'/new/' + news[1].id"><div class="w-full xl:w-auto xl:max-w-[640px] xl:mr-[14px] h-auto relative hover:bg-blue-200 bg-white rounded-[15px]">
        <img class="aspect-w-16 aspect-h-9 w-full h-[400px] object-cover rounded-[15px]" :src="'https://zodchiy72.ru/strapi/'+news[1].attributes.Img.data.attributes.url" />
        <div class="w-full p-6 overflow-hidden text-justify text-gray-800 text-[12px] md:text-[18px] font-medium leading-snug montsr">
          {{ news[1].attributes.shortDescription }}
        </div>
      </div></router-link>

    </div>

    <div class="w-full xl:w-[50%] xl:my-auto text-left">
      <div class="w-full h-max">
        <SmallNewCard :news='news' index="2"/>
        <SmallNewCard :news='news' index="3"/>
        <SmallNewCard :news='news' index="4"/>
        <SmallNewCard :news='news' index="5"/>
        <router-link to="/news">
          <div class="flex justify-center">
            <div class="h-10 w-full rounded-[15px] text-lg text-black border-blue-600 flex justify-center items-center hover:bg-blue-700 border hover:text-white hover:border-hidden montsr">
              Все новости
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>



<script>
  import { defineComponent } from 'vue'
  import SmallNewCard from './SmallNewCard.vue'


  export default defineComponent({
    name: 'MidNewCard',

    props:{
      news: Array,
    },

    components: {
      SmallNewCard,

    },
    // data() {
    //   return {
    //     images: [
    //       require('../assets/CarouselPhoto/img1.png'),
    //       require('../assets/CarouselPhoto/img1.png'),
    //       require('../assets/CarouselPhoto/img1.png'),
    //       // добавьте пути к другим изображениям здесь
    //     ],
    //   }
    // },
  })
</script>
