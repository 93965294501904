<template>
  <NavBar/>


  <div class="text-center montsr font-bold text-[23px] md:text-[36px] mt-[86px] line-clamp-2">{{ info.Header }}</div>

  <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-24 mt-[32px]">
    <div>
      <img class="aspect-w-16 aspect-h-9 w-[800px] mx-auto mb-[32px] rounded-[15px] object-cover" :src="imgPath">
      <div class="flex my-[28px] flex-row items-center">
        <img src="../../assets/icons/TimeNewsIco.png" class="h-[20px] w-[20px]">
        <p class="text-stone-500 text-opacity-80 text-[15px] montsr leading-[14px] pl-[8px]">{{ formatDate(info.createdAt) }}</p>
      </div>
    </div>
    <div v-html="this.info.description" />

  </div>

  <div class="flex h-[600px] md:h-[435px]"/><Footer/>

</template>





<script>
import NavBar from '../sharing/NavBar.vue'
import axios from 'axios';
import Footer from '../sharing/FooterBar.vue'

export default {
  components: {
    NavBar,
    Footer

  },
  data() {
    return {
      info: [],
      imgPath: '',
    }
  },
  methods: {
    formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    }
  },
  async mounted() {
    try {
      const response = await axios.get('https://zodchiy72.ru/strapi/api/novostis/' + this.$route.params.id + '?populate=deep')
      this.info = response.data.data.attributes
      this.imgPath = 'https://zodchiy72.ru/strapi' + this.info.Img.data.attributes.url
    } catch (error) {
      this.error = error;
    }
  }
}
</script>
