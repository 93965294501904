<template>
  <div class='absolute w-screen backdrop-blur-[4px] bg-gradient-to-b from-blue-700/60 via-blue-700/50 left-0 h-[180px] z-30 rounded-xl'/>
  <div class="site-column h-screen">
    <!-- <div class='backdrop-blur-[2px]  bg-gradient-to-b from-blue-700/60 via-blue-700/40 rounded-xl py-4 px-4'/> -->
    <!-- фон -->
    <div style="background: linear-gradient(180deg, rgba(30, 80, 217, 0.477) 10%, rgba(30, 80, 217, 0.047) 55%, rgba(85, 130, 255, 0)  100%)" class="absolute z-20 top-0 h-screen left-0 w-full"></div>

    <div class=" h-screen absolute bg-opacity-70 top-0 left-0 w-full">
      <img class="absolute top-0 left-0 w-full h-screen object-cover  object-bottom" :src="bg"
      :class="{ 'object-center': align == 'Выравнивание по центру', 'object-top': align == 'Выравнивание по верху', 'object-bottom': align == 'Выравнивание по низу' }"
      />

      <div class="h-16 w-16 xl:w-28 xl:h-28 ml-auto absolute right-[8%] bottom-64 sm:bottom-52 xl:bottom-80 animate-[bounce_4500ms_infinite]  stroke-white stroke-[0.5px]">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.46997 10.74L12 14.26L15.53 10.74" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="h-16 w-16 xl:w-28 xl:h-28  ml-auto absolute left-[8%] bottom-64 sm:bottom-52 xl:bottom-80 animate-[bounce_4500ms_infinite] stroke-white stroke-[0.5px]">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.46997 10.74L12 14.26L15.53 10.74" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

    </div>
    <div class="pt-4 ">
      <!-- контент -->
      <div class="relative z-30">
          <navBar bghide="true"/>
          <!-- bg-blue-700/20 -->
          <div class="my-[66px] md:my-[80px] backdrop-blur-[2px] bg-gradient-to-b from-blue-700/20 mx-auto via-cyan-600/40 rounded-xl py-4 w-fit px-4 items-center md:items-start flex-col flex justify-center">
            <div class="text-gray-200 font-bold montsr text-center md:ml-0 text-[28px] sm:text-[32px] md:text-5xl flex">Спортивно-оздоровительный</div>
            <div class="text-gray-200 font-bold montsr text-center md:ml-0 text-[28px] sm:text-[32px] md:text-5xl w-full mt-3">центр «ЗОДЧИЙ»</div>
            <div class="text-gray-200 text-xl leading-snug text-center my-[32px] font-bold mx-auto montsr">Место твоих достижений</div>

            <router-link to="services" class="text-blue-700 py-2 px-16 rounded-xl bg-white/90 hover:bg-white/0 hover:border-white hover:scale-110 hover:text-white duration-700 border-2 border-white/0 hover:border-2 mx-auto text-xl font-bold">Записаться</router-link>
          </div>

      </div>




    </div>
  </div>
</template>


<script>
  import axios from 'axios';
  import navBar from '../sharing/NavBar.vue'
  export default {

    components:{
      navBar
    },


    data() {
      return {
        bg: '',
        align: '',
        isOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },

      scrollDown() {
        // Получаем высоту экрана браузера
        const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        // Прокручиваем страницу на высоту экрана
        window.scrollBy(0, screenHeight);
      },
    },
    async mounted () {
      try {

        const response = await axios.get('https://zodchiy72.ru/strapi/api/main-background?populate=bg')
        this.bg = 'https://zodchiy72.ru/strapi' + response.data.data.attributes.bg.data.attributes.url
        this.align = response.data.data.attributes.alignment

        if(response != undefined && this.bg != '' && this.align != ''){
          this.$emit('loaded', true);
        }

      } catch (error) {
        this.error = error;
      }
    }
  };
</script>