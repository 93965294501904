
<template>
  <div class="my-4">
    <div @click="toggleAccordion" class="accordion-header bg-blue-500 text-white rounded-tl-lg rounded-tr-lg flex items-center">
      <button  class="w-full py-1 px-4 text-left text-[16px] md:text-2xl flex">
        {{ accordionTitle }}
      </button>
      <span class="hidden md:flex accordion-arrow" :class="{ 'open': isAccordionOpen }"></span>
    </div>
    <div v-show="isAccordionOpen" class="accordion-content bg-white mb-4 rounded-b-lg">
      <p class="px-4 py-2 text-black whitespace-pre-line text-lg" v-html="formattedContent"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accordionTitle: {
      type: String,
      required: true,
    },
    accordionContent: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedContent() {
      return this.accordionContent;
    },
  },
  data() {
    return {
      isAccordionOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
  },
};
</script>
<style>
.accordion-header {
  background-color: #2563EB;
}

.accordion-arrow {
  width: 10px;
  height: 10px;
  margin-right: 16px;
  border-bottom: 2px solid #FFF;
  border-right: 2px solid #FFF;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.accordion-arrow.open {
  transform: rotate(-135deg);
}

.accordion-content {
  background-color: #FFFFFF;
  border-radius: 0 0 15px 15px;
}

@media (max-width: 767px) {
  .accordion-header {
    flex-direction: column;
    align-items: center;
  }

  .accordion-arrow {
    margin-right: 0;
    margin-top: 8px;
  }
}
</style>