<template>
  <div>
    <NavBar/>
    <div class="text-gray-800 text-[38px] md:text-[48px] font-bold leading-[48px] mt-[72px] md:mt-[135px] md:mb-[72px] mb-[48px] text-center md:text-left montsr">Наши услуги</div>
    <SearchBar class="hidden"/>

<!-- ! -->
    <div class="grid grid-cols-1 mb-[72px] md:grid-cols-2 gap-[8px]">
      <div v-for="item in categories" :key="item.id" >
        <button
          class="w-full py-2 px-4 text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-700 hover:text-white hover:border-blue-700"
          @click="filterServices(item)"
          :class="{ 'bg-blue-700 text-white border-blue-700': selCategory === item }">
          {{item.name}}
        </button>
      </div>
      <button
        class="w-full py-2 px-4 text-center rounded-[12px] border-[2px] border-blue-500 border-dashed font-bold montsr hover:bg-blue-600 hover:text-white hover:border-blue-600"
        @click="filterServices('')" v-if="selCategory != ''">
        Назад
      </button>
    </div>
    <!-- ! -->


    <div class="flex justify-center">
      <div class="grid grid-cols-1 sm2:grid-cols-2 lg:grid-cols-3 1xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-10">
        <div v-for="item in catalog" :key="item.id">
          <CardService class="" :imgPath="'https://zodchiy72.ru/strapi'+item.attributes.photo.data.attributes.url" :name="item.attributes.name" :price="item.attributes.price" :id="item.id" />
        </div>
      </div>
    </div>


    <LoadMoreButton @click="loadMore(true)" class="mt-[72px]" v-if="showLoadButton == true && page != countPage"/>
    <loader v-if="showLoadButton == false" class=""/>

    <div class="flex h-[777px] md:h-[460px]"/><Footer class=""/>
  </div>

  <fsloader v-if="catalog.length < 1"/>
</template>

<script>
import loader from '../sharing/SiteLoader.vue'
import fsloader from '../sharing/fullscreenLoader.vue'
import NavBar from '../sharing/NavBar'
import SearchBar from '../sharing/SearchBar'
import CardService from './CardService.vue'
import LoadMoreButton from '../sharing/LoadMore.vue'
import Footer from '../sharing/FooterBar.vue'
import axios from 'axios';

export default {
  components: {
    fsloader,
    loader,
    NavBar,
    Footer,
    SearchBar,
    CardService,
    LoadMoreButton,
  },
  data () {
    return {
      showLoadButton: true,
      page: 1,
      countPage: 1,
      catalog: [],

      categories: [],
      selCategory: '',
      error: null
    }
  },
  methods: {
    filterServices(selCategory){
      if(this.selCategory != selCategory){
        this.selCategory = selCategory
        this.loadMore(false)
      }
    },

    async loadMore(btn){
      this.showLoadButton = false
      this.page++
      if(btn == false){
        this.page = 1
        this.catalog = []
      }

      var responseUrl = 'https://zodchiy72.ru/strapi/api/catalogs?fields[0]=name&fields[1]=price&populate=photo&sort=id:DESC&pagination[pageSize]=10&pagination[page]='+this.page

      if(this.selCategory != ''){
        console.log(this.selCategory.pomeshheniya_dlya_raspisaniyas)
        for (let i = 0; i < this.selCategory.pomeshheniya_dlya_raspisaniyas.data.length; i++) {
          responseUrl = responseUrl + '&filters[pomeshheniya_dlya_raspisaniya][name][$eq]=' + this.selCategory.pomeshheniya_dlya_raspisaniyas.data[i].attributes.name
        }
        // responseUrl = responseUrl + '&filters[pomeshheniya_dlya_raspisaniya][name][$eq]=' + this.selCategory
      }
      var response = await axios.get(responseUrl)
      this.countPage = response.data.meta.pagination.pageCount


      var data = response.data.data
      data.forEach((element) => {
        this.catalog.push(element);
      });
      this.showLoadButton = true
    },

  },

  async mounted () {
      try {

        // Получаем услуги
        const response = await axios.get('https://zodchiy72.ru/strapi/api/catalogs?fields[0]=name&fields[1]=price&populate=photo&sort=id:DESC&pagination[pageSize]=10&pagination[page]=1')
        this.catalog = response.data.data
        this.countPage = response.data.meta.pagination.pageCount

        // Получаем категории для фильтра
        const responseCategories = await axios.get('https://zodchiy72.ru/strapi/api/filtr-uslugis/?populate=*')
        for (let i = 0; i < responseCategories.data.meta.pagination.total; i++) {
          this.categories.push(responseCategories.data.data[i].attributes)
        }



      } catch (error) {
        this.error = error;
      }

    }
  }
</script>
