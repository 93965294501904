<template>
  <div class="w-[310px] h-[345px] relative bg-gradient-to-b p-3 from-blue-700 to-blue-500 rounded-[15px] gradient-animation flex justify-between items-center  flex-col">
    <div class="text-white text-[24px] font-bold leading-normal montsr">Все услуги</div>
    <img class="w-48 aspect-h-16 aspect-w-9 " src="../../assets/logo.png" />
    <router-link to="/services" class="h-10 w-full rounded-[15px] border border-white flex justify-center items-center hover:bg-white hover:text-black text-white text-[14px] font-bold leading-10 montsr">Подробнее</router-link>
  </div>
</template>

<style>
.gradient-animation {
  background: linear-gradient(to bottom right, #4882e0, #1948c9);
  background-size: 200% 200%;
  animation: gradientAnimate 2s ease-in-out infinite;
}

@keyframes gradientAnimate {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>

<script>

export default {
  name: 'OtherServices',

  components: {
  },

}
</script>
