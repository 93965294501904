<template>
  <div class="mt-[100px] items-center mb-[100px]">
    <div class="w-full h-12 relative my-[72px] flex flex-col md:flex-row text-center justify-start">
      <div class="w-auto text-gray-800 text-[48px] flex font-bold leading-[48px] montsr">Услуги</div>
      <router-link to="/services" class="w-full my-5 mx-3 hidden md:flex text-zinc-600 text-opacity-75 text-lg font-bold flex-1 montsr">Показать все ></router-link>
    </div>
    <center>
      <div class="grid grid-cols-1 w-full md:grid-cols-2 lg2:grid-cols-3 1xl:grid-cols-4 3xl:grid-cols-5 gap-[32px]">
        <div v-for="item in catalog" :key="item.id">
          <CardService class="" :imgPath="'https://zodchiy72.ru/strapi'+item.attributes.photo.data.attributes.url" :id="item.id" :name="item.attributes.name" :price="item.attributes.price" v-if="item.id != catalog[catalog.length -1].id"/>
          <OtherServices v-if="item.id == catalog[catalog.length -1].id"/>
        </div>
      </div>
    </center>





  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  // import { Carousel, Navigation, Slide } from 'vue3-carousel';
  // import 'vue3-carousel/dist/carousel.css';
  import CardService from "../ServicesPage/CardService.vue";
  import axios from 'axios';
  import OtherServices from './OtherServicesMini.vue';

  export default defineComponent({
    name: 'ServicesC',
    components: {
      CardService,
      OtherServices,
      // Carousel,
      // Slide,
      // Navigation,
    },

    data() {
      return {
        catalog: [],
        error: null,
        settings: {
        itemsToShow: 4,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      breakpoints: {
        300: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 700px and up
        700: {
          itemsToShow: 1.4,
          snapAlign: 'center',
        },
        800: {
          itemsToShow: 1.6,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1.8,
          snapAlign: 'center',
        },
        1280: {
          itemsToShow: 2.2,
          snapAlign: 'center',
        },
        1350: {
          itemsToShow: 2.7,
          snapAlign: 'center',
        },
        1400: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        1650: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        2500: {
          itemsToShow: 3.8,
          snapAlign: 'center',
        },
      },
      }
    },
    async mounted () {
          try {
            const response = await axios.get('https://zodchiy72.ru/strapi/api/uslugi-vitrina?populate[uslugis][fields][0]=name&populate[uslugis][fields][1]=price&populate[uslugis][populate][photo][fields][2]=url')
            let ServicesNum = 4
            if(document.documentElement.clientWidth <= 500){
               ServicesNum = 2
            }
            for (var i = 0; i < ServicesNum; i++) {
              this.catalog.push(response.data.data.attributes.uslugis.data[i])
            }
            this.catalog.push('')

            if(response != undefined && this.catalog.length != 0){
              this.$emit('loaded', true);
            }
          } catch (error) {
            this.error = error;
          }
        },
  }
  )
</script>

