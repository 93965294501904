<template>
  <div class="my-4">
    <div @click="toggleAccordion" class="accordion-header bg-blue-500 text-white rounded-tl-lg rounded-tr-lg flex items-center">
      <button class="w-full py-1 px-4 text-left text-[16px] md:text-2xl flex">
        {{ accordionTitle }}
      </button>
      <span class="hidden md:flex accordion-arrow" :class="{ 'open': isAccordionOpen }"></span>
    </div>
    <div v-show="isAccordionOpen" class="accordion-content bg-white mb-4 rounded-b-lg">
      <div class="px-4 py-2 text-black whitespace-pre-line text-lg">
        <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 pt-4 md:px-[66px]">

          <div v-for="(doc, index) in accordionContent" :key="doc.id" :class="getJustifyClass(index)">
            <button @click="downloadFile('https://zodchiy72.ru/strapi/'+doc.attributes.document.data.attributes.url)" class="pb-4 text-start flex overflow-hidden montsr line-clamp-1 text-neutral-700 text-[16px] mx-0 md:mx-2 ">
              <div class="flex flex-row items-center gap-1">
                <img class="w-[18px] h-[20px]" src="../../assets/icons/docs.png" alt="">
                {{doc.attributes.docName}}
              </div>
            </button>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

export default {
  props: {
    accordionContent: {
      type: Array,
      required: true,
    },
    accordionTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
    };
  },
  methods: {
    async downloadFile(url) {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data]);
        const downloadLink = document.createElement('a');
        const fileName = this.getFileNameFromUrl(url);
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        console.error('Failed to download file', error);
      }
    },
    getFileNameFromUrl(url) {
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1];
    },

    getJustifyClass(index) {
      const classes = ['flex'];
      if (window.innerWidth <= 1536){
        index = 3
      }
      switch (index % 3) {
        case 0:
          classes.push('justify-start');
          break;
        case 1:
          classes.push('justify-center');
          break;
        case 2:
          classes.push('justify-end');
          break;
        default:
          break;
      }
      return classes.join(' ');
    },
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
  },
};
</script>
