<template>
  <router-link :to="'new/' + news[index].id"><div class="w-full mb-[14px] relative mrgn10">
    <div class=" h-100px lg:h-[101px] w-full relative bg-white hover:bg-blue-200 flex rounded-[15px]">
      <!-- <img class="max-w-[100px] lg:max-w-[160px] aspect-w-16 aspect-h-9 h-full object-center object-scale-down rounded-tl-[15px] flex rounded-bl-[15px]" v-if="news[index]" :src="'https://zodchiy72.ru/strapi'+news[index].attributes.Img.data.attributes.url"/> -->
      <svg class="h-12 fill-blue-700 w-12 mx-6 my-auto" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path class="" clip-rule="evenodd" fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h9A1.5 1.5 0 0114 3.5v11.75A2.75 2.75 0 0016.75 18h-12A2.75 2.75 0 012 15.25V3.5zm3.75 7a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zm0 3a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zM5 5.75A.75.75 0 015.75 5h4.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-4.5A.75.75 0 015 8.25v-2.5z"></path>
        <path class="" d="M16.5 6.5h-1v8.75a1.25 1.25 0 102.5 0V8a1.5 1.5 0 00-1.5-1.5z"></path>
      </svg>
      <div class="w-full overflow-hidden flex text-gray-800 text-[12px] md:text-lg font-medium items-center mx-[41px] leading-snug montsr">{{news[index].attributes.Header}}</div>
    </div>
  </div></router-link>
</template>

<style>
  @media (max-width: 1279px) {
    .mrgn10 {
      margin-top: 14px;
    }
  }
</style>

<script>
  import { defineComponent } from 'vue'


  export default defineComponent({
    name: 'SmallNewCard',
    props: {
      news: Array,
      index: String,
    },
    components: {

    },

  })
</script>
