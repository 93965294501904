<template>
  <NavBar/>
  <div class=" text-gray-800 text-[32px] md:text-5xl font-bold mt-[100px] mb-[40px] text-center md:text-left montsr">Расписание</div>

  <!-- Первый этап (возраст, помещение) -->
  <div v-if="selected.age.length < 2 && selected.location.length < 2">
    <div v-for="age in data.agesAndLocations" :key="age.id">
      <div class=" text-gray-800 text-[18px] md:text-[28px] mb-[12px] mt-[32px] font-bold text-center md:text-left montsr">{{ age.attributes.name }}</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-[8px]">
        <div v-for="location in age.attributes.pomeshheniya_dlya_raspisaniyas.data" :key="location.id">
          <button
            class="w-full py-2 px-4  text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-600 hover:text-white hover:border-blue-600"
            @click="selected.age = age.attributes.name; selected.location = location.attributes.name; GetUslugs()">
            {{location.attributes.name}}
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="grid grid-cols-1 md:grid-cols-2 gap-[8px]">
    <div v-for="item in workItems" :key="item">
      <button
        class="w-full py-2 px-4  text-center rounded-[12px] border-[2px] border-blue-500 font-bold montsr hover:bg-blue-600 hover:text-white hover:border-blue-600"
        @click="update(item)">
        {{item}}
      </button>
    </div>
  </div>

  <!-- Остальные выборы -->

  <scheduleList
    class="mt-[72px]"
    :events = schedule
    v-if="schedule.length > 2" />

  <fsLoader class="z-50" v-if="false"/>

  <!-- <footer /> -->

</template>


<script>
  import axios from 'axios';
  import fsLoader from '../sharing/fullscreenLoader.vue'
  import scheduleList from './schedule/scheduleList.vue'
  import NavBar from '../sharing/NavBar'
  // import footer from '../sharing/FooterBar.vue'

  export default {
    components: {
      NavBar,
      // footer,
      scheduleList,
      fsLoader,
    },

    data() {
      return {
        workItems: [], //обрабатываемые данные
        schedule: [], //Само Расписание

        selected: {
          age: String,
          location: String,
          usluga: String,
          trener: String,
        },

        data: {
          agesAndLocations: Array,
          uslugs: [],
          treners: Array,
        },

        props: {

        },

      };
    },
    async mounted() {
      this.data.agesAndLocations = (await axios.get('https://zodchiy72.ru/strapi/api/age?populate=pomeshheniya_dlya_raspisaniyas')).data.data
    },

    computed: {

    },

    methods: {
      update(item){
        if(this.selected.usluga.length < 2 && this.selected.trener.length < 2){ //после выбора услуги
          this.selected.usluga = item
          this.getSchedule().then( () => {
            this.getTreners()
          })
          this.workItems = []
        }else if(this.selected.trener.length < 2){ //после выбора тренера
          this.selected.trener = item
          this.getSchedule()
        }

      },

      async GetUslugs(){
        //Получаем услуги
        let response = await axios.get('https://zodchiy72.ru/strapi/api/schedule?populate[uslugi][fields][0]=name&fields[1]=&filters[pomeshheniya_dlya_raspisaniya][name][$eq]=' + this.selected.location + '&filters[ages][name][$eq]='+ this.selected.age +'&pagination[pageSize]=100&pagination[page]=1')
        let pageCount = response.data.meta.pagination.pageCount
        let tmpSchedule = []
        tmpSchedule.push(response.data.data)

        for (let i = 2; i < pageCount+1; i++) {
          response = await axios.get('https://zodchiy72.ru/strapi/api/schedule?populate[uslugi][fields][0]=name&fields[1]=&filters[pomeshheniya_dlya_raspisaniya][name][$eq]=' + this.selected.location + '&filters[ages][name][$eq]='+ this.selected.age +'&pagination[pageSize]=100&pagination[page]='+i)
          tmpSchedule.push(response.data.data)
        }

        for (let i = 0; i < tmpSchedule.length; i++) {
          for (let j = 0; j < tmpSchedule[i].length; j++) {
            if(!this.data.uslugs.includes(tmpSchedule[i][j].attributes.uslugi.data.attributes.name)){
              this.data.uslugs.push(tmpSchedule[i][j].attributes.uslugi.data.attributes.name)
            }
          }
        }
        this.workItems = this.data.uslugs
        this.getSchedule()

      },

      getTreners(){
        this.data.treners = []
        for (let i = 0; i < this.schedule.length; i++) {
          for (let j = 0; j < this.schedule[i].length; j++) {
            if(!this.data.treners.includes(this.schedule[i][j].title)){
              this.data.treners.push(this.schedule[i][j].title)
            }
          }
        }
        this.workItems = this.data.treners
      },

      async getSchedule(){
        let response = []
        let schedule = []
        let url = 'https://zodchiy72.ru/strapi/api/schedule?populate[trener][fields][0]=Name&populate[uslugi][fields][1]=name&fields[1]=day&fields[2]=description&fields[3]=StartTime&fields[4]=EndTime'
        if(this.selected.usluga.length > 2){
          url += '&filters[uslugi][name][$eq]=' + this.selected.usluga
        }
        if(this.selected.location.length > 2){
          url += '&filters[pomeshheniya_dlya_raspisaniya][name][$eq]=' + this.selected.location
        }
        if(this.selected.age.length > 2){
          url += '&filters[ages][name][$eq]=' + this.selected.age
        }
        if(this.selected.trener.length > 2){
          url += '&filters[trener][Name][$eq]=' + this.selected.trener
        }
        url += '&pagination[pageSize]=100&pagination[page]='

        for (let i = 1; i < 4; i++) {
          response = await axios.get(url+i)

          for (let j = 0; j < response.data.data.length; j++) {
            schedule.push(response.data.data[j].attributes)
          }

          if(i == response.data.meta.pagination.pageCount){
            break
          }
        }
        console.log(url)
        this.scheduleFormat(schedule)
      },

      scheduleFormat(tmpSchedule){
        let dayNum = 1
        this.schedule=[
          [], //пн
          [],
          [],
          [],
          [],
          [],
          [], //вс
        ]

        for (let i = 0; i < tmpSchedule.length; i++) {
          dayNum = this.convertDayToNumber(tmpSchedule[i].day) - 1
          this.schedule[dayNum].push(
            {
              day: this.convertDayToNumber(tmpSchedule[i].day),
              start: tmpSchedule[i].StartTime.split(':').slice(0, 2).join(":"),
              end: tmpSchedule[i].EndTime.split(':').slice(0, 2).join(":"),
              title: this.generateInitials(tmpSchedule[i].trener.data.attributes.Name),
              content: tmpSchedule[i].uslugi.data.attributes.name,
              description: tmpSchedule[i].description,
            },
          );
        }

      },

      convertDayToNumber(day) {
        const daysOfWeek = [
          "Понедельник",
          "Вторник",
          "Среда",
          "Четверг",
          "Пятница",
          "Суббота",
          "Воскресенье"
        ];

        const index = daysOfWeek.indexOf(day);

        // Если день недели не найден, вернуть -1 или другое значение по вашему усмотрению
        // В данном случае, мы вернем -1
        return index !== -1 ? index + 1 : -1;
      },
      generateInitials(fullName) {
        const words = fullName.trim().split(' ');

        if (words.length >= 3) {

          return words[0] + ' ' + words[1][0] + '.' + ' ' + words[2][0] + '.';
        } else {
          return fullName;
        }
      },

    },
  };
</script>
