<template>
<router-view></router-view>
<ButtonUp/>


</template>

<script>
import ButtonUp from './components/sharing/ButtonUp.vue'



export default {
  components: {
    ButtonUp,
  }


}
</script>

<style>
  @import './index.css';
  .montsr{
    font-family: 'Montserrat', sans-serif;
  }
  ul {
    list-style-type: disc; /* Квадратные маркеры */
  }
  table
    {
      margin-top: 16px;
      width: 100%;
      border-collapse: collapse;
      border: 2px solid #3b82f6;
    }
  th, td {
      border: 2px solid #3b82f6;
    }
</style>
