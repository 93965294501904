<template>
  <div class="w-[290px] h-[345px] relative bg-gradient-to-b from-blue-700 to-blue-500 shadow-lg rounded-[15px]">
    <img class="aspect-w-16 aspect-h-9 w-full h-[180px] object-cover rounded-[15px]" :src="imgPath"/>

    <div class="w-auto h-[80px] text-center text-white text-[18px] leading-5 font-medium px-2 my-3">{{name}}</div>
    <!-- <div class="w-full h-auto text-justify overflow-hidden line-clamp-5 text-white text-[14px] px-2 ">
    </div> -->
    <div class="w-full h-auto bottom-[43px] absolute">
      <div class="h-1"></div>
      <div class="relative justify-center my-2 mx-2">
        <div class="w-1/2 h-fill left-0 flex flex-row items-center my-3 absolute">
          <img src="../../assets/icons/card.png" class="w-[23px] mx-2" alt="">
          <div class="text-white text-[14px] leading-snug montsr">От <b>{{ price }}</b> руб.</div>
        </div>
          <router-link :to="'service/' + id" class=" absolute right-0 h-10 w-1/2 rounded-[15px] border text-white border-white flex justify-center items-center text-[14px] hover:bg-blue-700 hover:border-hidden">Подробнее</router-link>
          <div class="h-[1px]"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardService',
  props: {
    name: String,
    description: String,
    price: Number,
    id: Number,
    imgPath: String,
  }
}
</script>

