<template>
    <div style="height: 100px;" class="absolute bg-opacity-70 top-0 left-0 w-full"
    :class="{'gradient-animation2': bghide == false}"
    />
    <div class="pt-4 ">
      <!-- контент -->
      <div class="relative ">
        <!-- menu -->
        <div class="lg:flex items-center justify-between hidden ">
            <!-- Логотип -->
            <router-link to="/">
              <img src="../../assets/navlogo.png" alt="Логотип" class="w-auto h-auto flex-shrink-0" style="max-width: 141px; max-height: 69px; object-fit: cover;">
            </router-link>


          <div
          class="flex items-center justify-center space-x-2 xl:space-x-6 1xl:space-x-14 mt-4 md:mt-0"
          >
            <!-- Пункты меню -->
            <router-link to="/" class="hover:underline montsr text-white">Главная</router-link>
            <router-link to="/news" class="hover:underline montsr text-white">Новости</router-link>
            <router-link to="/services" class="hover:underline montsr text-white ">Услуги</router-link>
            <router-link to="/schedule" class="hover:underline montsr text-white ">Расписание</router-link>
            <router-link to="/team" class="hover:underline montsr text-white ">Команда</router-link>
            <router-link to="/docs" class="hover:underline montsr text-white">Документы</router-link>
            <router-link to="/contactsPage" class="hover:underline montsr text-white">Контакты</router-link>
            <button @click="reSchedulingWindows++" class="hover:underline montsr text-white">Перенос занятий</button>
          </div>

            <!-- Кнопка Войти -->
          <router-link to="/auth" class="inline-block text-white border border-white md:mt-2 ml-1 w-[141px] text-center px-8 py-2 rounded-full font-bold montsr hover:bg-white/80 hover:text-blue-700 hover:scale-110 duration-500 ">Войти</router-link>
        </div>

        <!--  -->


    <!-- !Mobilemenu -->
    <div class="-mr-2 flex ab w-full lg:hidden">
      <button @click="toggleMenu" type="button" class=" w-full inline-flex items-center justify-center p-2 rounded-md text-white">
        <img src="../../assets/navlogo.png" alt="Логотип" class="w-auto h-auto flex-shrink-0" style="max-width: 94px; max-height: 46px; object-fit: cover;">
        <svg class="block h-12 w-6 ml-auto mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
    <div :class="{'block': isOpen, 'hidden': !isOpen}" class="lg:hidden w-full">
      <div class="px-2 pt-2 w-full z-50 absolute mt-5 pb-3 gradient-animation space-y-1 rounded-[15px] rounded-br-[15px] rounded-bl-[15px] sm:px-3">
        <router-link to="/" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Главная</router-link>
        <router-link to="/news" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Новости</router-link>
        <router-link to="/services" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Услуги</router-link>
        <!-- !!! -->
        <router-link to="/schedule"  @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Расписание</router-link>
        <!-- !!! -->
        <button @click="toggleMenu(); reSchedulingWindows++" class="text-white w-full hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Перенос занятий</button>
        <router-link to="/team" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Команда</router-link>
        <router-link to="/docs" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Документы</router-link>
        <router-link to="/contactsPage" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Контакты</router-link>
        <router-link to="/auth" @click="toggleMenu" class="text-white hover:bg-blue-700 text-center hover:text-white block px-3 py-2 rounded-md text-base font-medium">Войти</router-link>
      </div>
    </div>
  </div>

    <!--!  -->

    </div>


    <div v-for="item in reSchedulingWindows" :key=item>
      <Rescheduling
        :show="true"
      />
    </div>

</template>


<script>

import Rescheduling from "../sharing/ReScheduling.vue"
export default {
  components: {
    Rescheduling,
  },

  props: {
    bghide: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      reSchedulingWindows: 0,
      isOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style>
  .gradient-animation2 {
    background: linear-gradient(to bottom right, #4d6cbe, #1e3a8a);
    background-size: 200% 200%;
    animation: gradientAnimate 2s ease-in-out infinite;
  }
  .gradient-animation {
    background: linear-gradient(to bottom right, #3B82F6, #1D4ED8, #1D4ED8);
    background-size: 200% 200%;
    animation: gradientAnimate 2s ease-in-out infinite;
  }

  @keyframes gradientAnimate {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
</style>