<template>
  <NavBar/>
  <div class="text-center montsr font-bold text-[23px] lg:text-[32px] mt-[48px] line-clamp-2 mb-[14px]">{{ info.Name }}</div>
  <img class="aspect-w-16 aspect-h-9 w-[420px] max-h-[450px] mx-auto mb-[46px] rounded-[15px] object-cover object-top" :src="imgPath">

  <div class="flex flex-wrap w-auto gap-2 justify-center">

    <div v-for="tag in tags" :key="tag.id">
      <div class="flex  px-2 py-2 w-auto flex-row items-center justify-center bg-blue-700 rounded-[15px]">
        <p class="text-white text-opacity-80 text-[16px] montsr leading-[14px] px-[8px] py-[4px]">{{tag}}</p>
      </div>
    </div>
  </div>


  <div class="mt-[23px] " v-html="this.info.description"/>

  <RouterLink :to="'/schedule?mytrener=' + info.Name" class="w-[250px] mx-auto md:mx-0 flex mt-[28px] py-3 items-center text-black hover:text-white justify-center border border-blue-700 hover:bg-blue-700 rounded-[15px]">
    <p class="text-opacity-80 text-[16px] montsr font-bold leading-[14px] pl-[8px]"> Расписание тренера</p>
  </RouterLink>




  <div class="flex h-[600px] md:h-[435px]"/><Footer/>

</template>

<script>
import NavBar from '../sharing/NavBar.vue'
import axios from 'axios';
import Footer from '../sharing/FooterBar.vue'


export default {
  components: {
    NavBar,
    Footer

  },
  data() {
    return {
      info: [],
      tags:[],
      imgPath: '',
      renderedDescription: '', // Add a new data property for the rendered description
    }
  },
  methods: {

  },
  async mounted() {
    try {
      const response = await axios.get('https://zodchiy72.ru/strapi/api/teams/' + this.$route.params.id + '?populate=deep')
      this.info = response.data.data.attributes
      if(this.info.photo.data === null){
        this.imgPath = 'https://zodchiy72.ru/strapi/uploads/placeholder_User_8782a4a086.png'
      }else{
        this.imgPath = 'https://zodchiy72.ru/strapi' + this.info.photo.data.attributes.url
      }
      this.tags = this.info.Tags.split(',');
    } catch (error) {
      this.error = error;
    }
  }
}
</script>
