<template>
  <NavBar/>
  <div class=" text-gray-800 text-[36px] md:text-[48px] font-bold leading-[48px] mt-[100px] mb-[72px] text-center md:text-left montsr">Новости</div>
  <loader v-if="news.length < 1" class=""/>

    <div v-for="oneNew in news" :key="oneNew.id">
      <NewsCard :id="oneNew.id" :date="formatDate(oneNew.attributes.createdAt)" :shortDescription="oneNew.attributes.middleDescription" :header="oneNew.attributes.Header" :imgPath="'https://zodchiy72.ru/strapi/'+oneNew.attributes.Img.data.attributes.url"/>
    </div>
    <LoadMoreButton @click="loadMore" class="mt-[72px]" v-if="showLoadButton == true && page < countPage"/>
    <loader v-if="showLoadButton == false" class=""/>

  <div class="flex h-[600px] md:h-[435px]"/><Footer/>
</template>

<script>

  import loader from '../sharing/SiteLoader.vue'
  import axios from 'axios';
  import NavBar from '../sharing/NavBar'
  import NewsCard from './NewsCard.vue'
  import LoadMoreButton from '../sharing/LoadMore.vue'
  import Footer from '../sharing/FooterBar.vue'


  export default {
    components: {
      loader,
      NavBar,
      NewsCard,
      LoadMoreButton,
      Footer,
    },
    data () {
      return {
        news: [],
        showLoadButton: true,
        page: 1,
        pageCount: 0,
        error: null
      }
    },
    methods:{
      async loadMore(){
        this.showLoadButton = false
        this.page++
        const response = await axios.get('https://zodchiy72.ru/strapi/api/novostis?populate=Img&sort=id:DESC&fields[0]=Header&fields[1]=middleDescription&fields[2]=createdAt&pagination[pageSize]=10&pagination[page]='+this.page)
        var data = response.data.data

        data.forEach((element) => {
          this.news.push(element);
        });
        this.showLoadButton = true
      },

      formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
      }
    },
    async mounted () {
      try {
        const response = await axios.get('https://zodchiy72.ru/strapi/api/novostis?populate=Img&sort=id:DESC&fields[0]=Header&fields[1]=middleDescription&fields[2]=createdAt&pagination[pageSize]=10&pagination[page]=1')
        this.news = response.data.data
        this.pageCount = response.data.meta.pagination.pageCount
      } catch (error) {
        this.error = error;
      }

    }
  }
</script>

