<template>
  <div class="flex relative flex-wrap my-[72px]">
    <div class="w-full xl:w-[50%] relative text-left">
      <div class="w-full xl:w-auto xl:max-w-[640px] xl:mr-[14px] h-auto relative rounded-[15px]">
        <img class="aspect-w-16 aspect-h-9 w-full rounded-[15px]" :src="'https://zodchiy72.ru/strapi'+ imgPath" />
      </div>
    </div>

    <div class="w-full xl:w-[50%] relative text-left">
      <div class="w-full h-max">
        <div class="h-auto w-full text-gray-800 text-justify md:text-left text-lg md:text-[28px] lg:text-[28px]  font-bold leading-5 my-2 xl:my-0 md:h-auto md:leading-10 line-clamp-2 montsr">{{ header }}
</div>
        <div class="w-full h-auto line-clamp-[3] xl:line-clamp-[7] 2xl:line-clamp-[9] md:line-clamp-[5] text-gray-800 text-[12px] md:text-[18px] my-1 overflow-hidden font-medium leading-[14px] md:leading-[30px] montsr">{{ middleDescription }}</div>
        <router-link :to="'/new/'+id">
          <div class="my-[24px] flex justify-center">
            <div class="h-10 w-full rounded-[15px] absolute left-0 bottom-0 text-lg border-blue-600 flex justify-center items-center bg-blue-600 font-medium text-gray-50 hover:bg-blue-500 montsr">Подробнее</div>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<style>

</style>

<script>
  import { defineComponent } from 'vue'


  export default defineComponent({
    name: 'MidNewCard',
    props: {
      header: String,
      middleDescription: String,
      imgPath: String,
      id: String,

    },

    components: {

    },

  })
</script>
