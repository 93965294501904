<template>
  <NavBar @loaded="OneLoaded"/>
  <ServicesC @loaded="OneLoaded" />
  <PhotoSlider @loaded="OneLoaded"/>



  <NewsBlock @loaded="OneLoaded"/>
  <AccordionsController/>
  <!-- <AchievementsList class="my-[135px]"/> -->
  <!-- <CardToServices/> -->
  <MapYa />

  <div class="flex h-[100px] md:h-[72px]"/><FooterBar class=""/>

  <fsLoader v-if="loaded == false"/>

</template>

<script>
import NavBar from './NavBar.vue'
import ServicesC from './ServicesC.vue'
import PhotoSlider from './PhotoSlider.vue'
import NewsBlock from './News/NewsBlock.vue'
// import AchievementsList from './AchievementsList.vue'
import AccordionsController from './AccordionsController.vue'
// import CardToServices from '../sharing/CardToServices.vue'
import MapYa from '../sharing/MapYa.vue'
import FooterBar from '../sharing/FooterBar.vue'
import fsLoader from '../sharing/fullscreenLoader.vue'


export default {
  name: 'App',
  components: {
    fsLoader,
    NavBar,
    ServicesC,
    PhotoSlider,
    NewsBlock,
    // AchievementsList,
    AccordionsController,
    // CardToServices,
    MapYa,
    FooterBar,
  },

  data(){
    return{
      loadedCount: 0,
      loaded: false,
    }
  },


  methods: {
    OneLoaded(){
      console.log(this.loadedCount)
      this.loadedCount++
      if(this.loadedCount >= 4)
      setTimeout(() => {
        this.loaded = true
      }, 1150)

    }
  }
}


</script>


